<template>
    <div id="siteMonitor">
        <div class="header">
            

            <p> {{ sites.length }} / 5 Sites</p>
        </div>

        <div v-for="(site, index) in sites" class="site">

           <div class="status">
                <i :class="site.status == 'Up' ? 'fa-regular fa-circle-check' : 'fa-regular fa-circle-xmark'"></i>

                <p>{{ site.status }}</p>
           </div>

           <a :href="site.url" class="url">
                {{ site.url }}
           </a>

           <div class="code">
                <div class="status-code">
                    {{ site.statusCode }}
                </div>
                
                {{ site.statusCodeDescription }}
           </div>

           <i class="far fa-trash-alt" title="Delete Site" @click="deleteSite(index)"></i>
        </div>


        <a v-if="sites.length < 5" @click="openPopup" class="btn-primary">
            <i class="fas fa-plus"></i>
            Add Site
        </a>

        
    </div>
  </template>
  
  <script>

    import Swal from 'sweetalert2';
    import $ from "jquery";
    import moment from 'moment';

  export default {
    name: 'Lighthouse',
    props:{

    },
    data: function () {
      return {
        sites: this.getSites(),
      }
    },
    mounted() {
        
        this.sites.forEach(site => {
            this.getSite(site.url);
        });

        setInterval(() => {
            this.sites.forEach(site => {
                this.getSite(site.url);
            });
        }, 300000);
        //300000 5 minutes
    },
    methods:{
        getSites: function(){
            var storedSites = localStorage.getItem("siteMonitors");

            if (storedSites == null || storedSites == "")
                return [];
                
            return JSON.parse(storedSites);
        },
        openPopup: function(){
            Swal.fire({
                title: "Add a Site",
                text: "Please enter the URL:",
                input: 'text',
                showCancelButton: true        
            }).then((result) => {
                if (result.value) {
                    this.getSite(result.value);
                }
            });
        },
        getSite: function(site){

            var self = this;

            const settings = {
                async: true,
                crossDomain: true,
                url: 'https://webup.p.rapidapi.com/checkurl?url=' + site,
                method: 'GET',
                headers: {
                    'X-RapidAPI-Key': 'b11910c071msh3ea041eaa90af26p14f827jsn36ddcc0c9d92',
                    'X-RapidAPI-Host': 'webup.p.rapidapi.com'
                }
            };

            $.ajax(settings).done(function (response) {

                var alreadyExistingSiteIndex = self.sites.findIndex(x => x.url == site);

                if (alreadyExistingSiteIndex < 0){
                    self.sites.push(response);
                } else {
                    self.sites[alreadyExistingSiteIndex] = response;
                }

                localStorage.setItem("siteMonitors", JSON.stringify(self.sites));
                
            });
        },
        deleteSite: function(index){
            this.sites.splice(index, 1);
            localStorage.setItem("siteMonitors", JSON.stringify(this.sites));
        }
        
    },
    computed: {
        
    },
  }
  </script>
  
  <style scoped>
   .site{
        display: flex;
        align-items: center;
        gap: 18px;
        margin-bottom: 10px;
        background: var(--darken-background);
        padding: 12px 8px;
        border-radius: 8px;
   }


   .status{
    display: flex;
    align-items: center;
    gap: 6px;
    min-width: 65px;
   }
   .status i{
        font-size: 28px;
   }
   
   .status i.fa-circle-check{
        color: var(--brand-green);
   }

   .status i.fa-circle-xmark{
        color: var(--brand-red);
   }

   .url{
    color: var(--font);
    text-decoration: none;
    font-size: 12px;
   }

   .code{
    font-size: 12px;
    text-align: right;
    width: 100%;
   }

   .status-code{
    font-size: 16px;
   }

   .fa-trash-alt{
    font-size: 16px;
    color: var(--brand-red);
   }
  </style>
  
<template>
    <div class="tray" :style="`background: linear-gradient(180deg, rgb(${backgroundColour}) -15%, var(--spotify-black) 30%);`">
        <div v-if="album != null" class="header">
            <i @click="closeAlbum" class="fa-solid fa-arrow-left"></i>
            <img :src="'https://images1-focus-opensocial.googleusercontent.com/gadgets/proxy?container=focus&refresh=2592000&url=' + encodeURIComponent(album.images[0].url) " crossorigin="anonymous" :alt="album.name">
            <div class="info">
                <p class="album-name">{{ album.name }}</p>
                <p class="album-details">{{ album.tracks.total}} songs</p>
            </div>
        </div>
        <div v-if="album != null" class="list">
            <song v-for="(song, index) in album.tracks.items" 
            :track="song" 
            :parent-uri="album.uri" 
            :index="index"
            :cover="album.images[2].url"
            :artist-name="album.artists[0].name"
            :track-name="song.name"
            :id="song.id"
            @open-album="openAlbum"></song>
        </div>
    </div>
   
</template>

<script>
    import $ from "jquery";
    import ColorThief from "colorthief";
    import loader from '@/components/utilities/loading.vue';
    import song from './track.vue';

    export default {
        name: 'spotify',
        data: function () {
            return {
                album: null,
                backgroundColour: '25 20 20'
            }
        },
        props: {
            albumId: Number
        },
        mounted () {
            this.getAlbum();
        },
        methods: {
            getAlbum: function(){
                var self = this;

                $.ajax({
                    url: "https://landingpagejenkins.azurewebsites.net/api/spotify/getAlbum",
                    data: {
                        access: window.spAccess,
                        id: self.albumId
                    },
                    success: function (response) {
                        self.album = JSON.parse(response);
                        self.scrollPlayingSongIntoView();

                        
                        

                        self.$nextTick(function () {
                            const colorThief = new ColorThief();
                            const img = document.querySelector('#spotify .tray .header img');

                            if (img.complete) {
                                self.backgroundColour = colorThief.getColor(img);
                            } else {
                                img.addEventListener('load', function() {
                                    self.backgroundColour = colorThief.getColor(img);
                                });
                            }
                        });
                    }
                });
            },
            closeAlbum: function(){
                this.$emit("close-album");
            },
            openAlbum: function(id){
                this.$emit("open-album", id);
            },
            scrollPlayingSongIntoView: function (){
                

                setTimeout(() => {
                    var elem = this.$el.querySelector('.track.playing');

                    if(elem == null)
                        return;

                    var topPos = elem.offsetTop
                    scrollTo(this.$el, topPos - 100); 
                }, 100);
                  
                function scrollTo(element, to) {
                    element.scrollTop = to;
                }
            }
        },
        watch: {
            albumId: function (value, oldValue) { 
                this.album = null;
                this.backgroundColour = '25 20 20';
                this.getAlbum();
            }
        },
        computed: {
            // backgroundGradient: function () {
            //     return `background: linear-gradient(180deg, ${this.backgroundColour} -15%, var(--spotify-black) 30%);`
            // }
        },
        components: {
            loader,
            song
        },
    }
</script>

<style scoped>

.header{
    padding-top:12px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


.fa-arrow-left{
    position: absolute;
    top: 16px;
    left: 16px;
    background: var(--spotify-black);
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 32px;
}

.header img{
    height: 180px;
}

.info{
    text-align: center;
}
.album-name{
    font-size: 22px;
}
.info p{
    margin: 8px 0;
}

.list{
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: revert;
    padding-bottom: 8px;
}
</style>
<template>
    <div id="news">
        <div class="categories">
            <div v-for="category in categories" :class="selectedCategory == category ? 'category selected' : 'category'" @click="selectedCategory = category">
                {{ category }}
            </div>
        </div>

        <div v-if="News == null" class="listing" :style="'grid-template-columns: repeat('+ itemsToShow / 2 +', 1fr)'">
            <div class="loader grid-item" v-for="i in itemsToShow">
                <div class="card"></div>
            </div>
        </div>

        <div v-else class="listing" :style="'grid-template-columns: repeat('+ itemsToShow / 2 +', 1fr)'">
            <item v-for="(item, index) in filteredItems" v-bind:item="item" :index="index"/>
        </div>

    </div>
</template>

<script>

import $ from "jquery";
import item from '/src/components/widgets/news/item.vue'
import loader from '/src/components/utilities/loading.vue'
import { nextTick } from 'vue'

export default {
  name: 'news',
  props:{
  },
  data: function () {
    return {
        News: null,
        categories: ['general', 'world', 'nation', 'business', 'technology', 'entertainment', 'sports', 'science', 'health'],
        url: 'https://landingpagejenkins.azurewebsites.net/api/general/getnews?category=',
        itemsToShow: 4,
        selectedCategory: localStorage.getItem("preferred-category") || 'general'
    }
  },
  mounted() {
    var self = this;
    this.getItems();
    
    // setTimeout(() => {
    //     this.setNoToShow();
    // }, 500);
    nextTick(() => {
        this.setNoToShow();
    })

    $(window).resize(function () { 
        self.setNoToShow();
    });
  },
  methods: {
    getItems: function(){
        var self = this;
        self.News = null;

        $.ajax(this.url + this.selectedCategory).done(function (response) {
            response = JSON.parse(response)
            self.News = response.articles;
        });
    },
    setNoToShow: function(){
        var width = $(this.$el).width();
        var toShow = 2; 
        
        if (width > 1000){
            toShow = 10
        } else if (width > 800){
            toShow = 8
        } else if (width > 600){
            toShow = 6
        } else if (width > 400){
            toShow = 4
        } 

        this.itemsToShow = toShow;
    }
  },
  computed: {
    filteredItems: function () {
            return this.News.slice(0, this.itemsToShow);
        }
    },
    watch: {
        selectedCategory: function (value) {
            localStorage.setItem("preferred-category", value);
            this.getItems();
        }
    },
  components: {
    item,
    loader
  }
}
</script>

<style scoped>
#news{
    
    background: none;
    box-shadow:none;
    padding: 0;
    display: flex;
    flex-direction: column;
}

#news .listing{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    height: 100%;
    min-height: 512px;
}

.categories{
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    margin-bottom: 6px;
}

.category{
    background: var(--widget-background);
    font-size: 14px;
    padding: 2px 10px;
    text-transform: capitalize;
    border-radius: 20px;
    cursor: pointer;
    transition: 0.2s;
}

.category:hover, .category.selected{
    color: var(--widget-background);
    background: var(--font);
}

.grid-item{
    box-shadow: var(--shadow);
    border-radius: var(--border-radius);
    color: white;
    overflow: hidden;
}
.card{
    position: relative;
    min-height: 250px;
    height: 100%;
}
</style>
<template>
    <div class="colour-scheme">

        <div class="inputs">
            <div class="colour-options">
    <div class="colour">
        <label>Primary</label>
        <span>
            <input type="color" v-model="theme.primary" @change="updateTheme">
            <input type="text" v-model="theme.primary" @change="updateTheme">
        </span>
    </div>
    <div class="colour">
        <label>Background</label>
        <span>
            <input type="color" v-model="theme.background" @change="updateTheme">
            <input type="text" v-model="theme.background" @change="updateTheme">
        </span>
    </div>
    <div class="colour">
        <label>Font Colour</label>
        <span>
            <input type="color" v-model="theme.font" @change="updateTheme">
            <input type="text" v-model="theme.font" @change="updateTheme">
        </span>
    </div>
    <div class="colour">
        <label>Widget Background</label>
        <span>
            <input type="color" v-model="theme.widgetBackground" @change="updateTheme">
            <input type="text" v-model="theme.widgetBackground" @change="updateTheme">
        </span>
    </div>
    <div class="colour">
        <label>Font Weight</label>
        <span>
            <input type="number" v-model="theme.fontWeight" @change="updateTheme" step="100" min="100" max="900"> 
        </span>
    </div>
    
</div>
 <hr/>

    <h3>Box Shadow</h3>
    <div class="colour-options">
        <div class="shadow-option">
            <label>X</label>
            <input type="number" v-model="theme.shadow.x" @change="updateTheme">
        </div>
        <div class="shadow-option">
            <label>Y</label>
            <input type="number" v-model="theme.shadow.y" @change="updateTheme">
        </div>
        <div class="shadow-option">
            <label>Blur</label>
            <input type="number" v-model="theme.shadow.blur" @change="updateTheme">
        </div>
        <div class="shadow-option">
            <label>Spread</label>
            <input type="number" v-model="theme.shadow.spread" @change="updateTheme">
        </div>
        <div class="colour">
            <label>Colour</label>
            <span>
                <input type="color" v-model="theme.shadow.colour" @change="updateTheme">
                <input type="text" v-model="theme.shadow.colour" @change="updateTheme">
            </span>
        </div>
    </div>
    <hr/>

    <h3>Border Radius</h3>
    <div class="colour-options">
        <div class="shadow-option">
            <label>PX</label>
            <input type="number" v-model="theme.borderRadius" @change="updateTheme">
        </div>
    </div>

        </div>

        <div class="generator">
            <a @click="generateScheme" class="btn-primary">
                <i class="fa-solid fa-dice"></i>
                Generate Colour Scheme
            </a>

            <div class="random-colours" :loading="isLoading">
                <div  v-for="colour in randomTheme" class="random-colour" >
                    <div class="colour" :style="'background: '+ colour + ';'">

                    </div>
                        <span>
                        {{ colour }}
                    </span>

                    
                </div>

                <i class="fa-solid fa-spinner fa-spin"></i>
                
            </div>

            <a @click="applyRandom" class="btn-primary">
                Apply
            </a>
        </div>
    </div>

</template>

<script>

import {getTheme, setTheme} from "/src/helper.js";
import $ from "jquery";

export default {
  name: 'color-scheme',
  data: function () {
    return {
      theme: getTheme(),
      randomTheme: [
        getTheme().background, getTheme().primary, getTheme().font 
      ]
    }
  },
  methods: {
    updateTheme: function(){
        setTheme(this.theme);
    },
    generateScheme: function(){
        this.isLoading = true;
        var self = this;

        $.ajax({
            url: 'http://colormind.io/api/',
            crossDomain: true,
            method: 'post',
            contentType: 'application/x-www-form-urlencoded',
            data: '{"model":"default"}'
        }).done(function(response) {

            var scheme = JSON.parse(response)
            
            
            for (let index = 0; index < 3; index++) {
                self.randomTheme[index] = rgbToHex(scheme.result[index][0], scheme.result[index][1], scheme.result[index][2])
            }

            self.isLoading = false;
        });


        function componentToHex(c) {
            var hex = c.toString(16);
            return hex.length == 1 ? "0" + hex : hex;
        }

        function rgbToHex(r, g, b) {
            return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
        }
    },
    applyRandom: function(){
        this.theme.background = this.randomTheme[0];
        this.theme.widgetBackground = this.randomTheme[0];
        this.theme.primary = this.randomTheme[1];
        this.theme.font = this.randomTheme[2];

        this.updateTheme();
    }
  }
}
</script>

<style scoped>

.colour-scheme{
    display: flex;
    gap: 18px;
}
.inputs{
    width: 100%;
}
.generator{
    width: 600px;
}

.random-colours{
    display: flex;
    gap: 12px;
    margin: 12px 0;
    position: relative;
}

.random-colours .random-colour{
    width: 100%;
    pointer-events: none;
    user-select: none;
}

.random-colours .fa-spinner {
    position: absolute;
    top: calc(50% - 36px);
    left: calc(50% - 27px);
    font-size: 54px;
    display: none;
}
.random-colours[loading="true"] .random-colour{
    opacity: 0.2;
}
.random-colours[loading="true"] .fa-spinner {
    display: block;
}

.random-colours .colour{
    width: 100%;
    aspect-ratio: 1 / 1;
    box-shadow: var(--shadow);
}
    .colour{
        display: flex;
        flex-direction: column;
    }

    .colour label{
        padding-bottom: 6px;
    }

    .colour span{
        display: flex;
    }
    .colour span input[type="color"]{
        aspect-ratio: 1 / 1;
        aspect-ratio: 1 / 1;
        height: 40px;
        width: auto;
    }
    .colour span input, .shadow-option > *{
        border: none;
        background: #ffffff3b;
        color: var(--font);
        width: 100%;
        min-height: 38px;
    }

    .colour-options{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 14px;
    }

    .shadow-option{
        display: flex;
    }

    .shadow-option > *{
        height: 36px;
        text-align: right;
    }

    .shadow-option label{
        width: auto;
        padding: 1px 16px;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>
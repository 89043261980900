<template>
    <textarea class="editorContainer" v-model="code">

    </textarea>
    <a class="btn" @click="saveCss">Save</a>

</template>

<script>

import {getCSS, setCSS} from "/src/helper.js";
//import {EditorView, keymap} from "@codemirror/view"
//import {defaultKeymap} from "@codemirror/commands"
//import * as CodeMirror from 'codemirror';

export default {
    name: 'theme',
    data: function () {
        return {
            code: getCSS(),
            cssModal: null,
        }
    },
    mounted() {
        //var editorContainer = document.querySelector('.editorContainer');
        
        // this.cssModal = CodeMirror.fromTextArea(editorContainer, {
        //     lineNumbers: true,
        //     mode: 'CSS',
        //     //value: this.code
        // })

        // this.cssModal = new EditorView({
        //     doc: this.code,
        //     extensions: [keymap.of(defaultKeymap)],
        //     parent: editorContainer,
        //     mode: 'CSS'
        // })
    },
    methods: {
        saveCss: function () {
            // debugger
            // var code = this.cssModal.getValue();
            setCSS(this.code);
        },
    },
}
</script>

<style scoped>
.editorContainer{
    margin-bottom: 12px;
    width: 100%;
    min-height: 400px;
    border: none;
    padding: 4px 10px;
}
    .btn{
        color: #333;
    background: var(--brand-green);
    padding: 4px 16px;
    border-radius: 8px;
    float: right;
    cursor: pointer;
    width: min-content;
    }

</style>
<template>

    <img :src="user.profile" id="profile" alt="profic pic" width="36">

    <div v-if="user.useOffice" id="office">
        <!-- <i class="fa fa-grip"></i> -->
        <div v-for="item in office" class="item" :title="item.title">
            <a :href="item.url" target="_blank">
                <img :src="item.img" :alt="item.title" width="36">
            </a>
        </div>
    </div>

    <div v-if="user.useMedia" id="media">
        <!-- <i class="fa fa-grip"></i> -->
        <div v-for="item in media" class="item" :title="item.title">
            <a :href="item.url" target="_blank">
                <img :src="item.img" :alt="item.title" width="36">
            </a>
        </div>
    </div>
    <div v-if="user.useGoogle" id="google">
        <!-- <i class="fa fa-grip"></i> -->
        <div v-for="item in google" class="item" :title="item.title">
            <a :href="item.url" target="_blank">
                <img :src="item.img" :alt="item.title" width="36">
            </a>
        </div>
    </div>
    
</template>

<script>

import {getUser} from "/src/helper.js";

export default {
  name: 'additionalMedia',
  data: function () {
    return {
        user: getUser(),
        office: [
            {
                title: 'Office',
                url: 'https://www.office.com/?auth=1',
                img: 'https://res.cdn.office.net/officehub/officestartresources/office-icon_light_and_dark_small.svg'
            },
            {
                title: 'Word',
                url: 'https://www.office.com/launch/word?auth=1',
                img: 'https://res-1.cdn.office.net/shellux/word_24x.21f886b59cf807ae5818458ffa8cecd4.svg'
            },
            {
                title: 'Excel',
                url: 'https://www.office.com/launch/excel?auth=1',
                img: 'https://assets.msn.com/staticsb/statics/latest/icons/office-icons/Excel_24x.svg'
            },
            {
                title: 'PowerPoint',
                url: 'https://www.office.com/launch/powerpoint?auth=1',
                img: 'https://assets.msn.com/staticsb/statics/latest/icons/office-icons/PowerPoint_24x.svg'
            },
            {
                title: 'Outlook',
                url: 'https://outlook.com',
                img: 'https://assets.msn.com/staticsb/statics/latest/icons/office-icons/Outlook_24x.svg'
            },
            {
                title: 'OneDrive',
                url: 'https://onedrive.live.com/',
                img: 'https://assets.msn.com/staticsb/statics/latest/icons/office-icons/OneDrive_24x.svg'
            },
            {
                title: 'OneNote',
                url: 'https://www.onenote.com/',
                img: 'https://assets.msn.com/staticsb/statics/latest/icons/office-icons/OneNote_24x.svg'
            },
            {
                title: 'To Do',
                url: 'https://to-do.microsoft.com/tasks/',
                img: 'https://assets.msn.com/staticsb/statics/latest/icons/office-icons/ToDo_24x.svg'
            },
            {
                title: 'Calendar',
                url: 'https://onedrive.live.com/calendar',
                img: 'https://assets.msn.com/staticsb/statics/latest/icons/office-icons/Calendar_24x.svg'
            },
        ],
        media: [
            // {
            //     title: 'Spotify',
            //     url: 'https://open.spotify.com/',
            //     img: '/src/assets/Spotify.png'
            // },
            {
                title: 'Netflix',
                url: 'https://www.netflix.com/gb/',
                img: '/src/assets/Netflix.png'
            },
            {
                title: 'Disney Plus',
                url: 'https://www.disneyplus.com/',
                img: '/src/assets/DisneyPlus.png'
            },
        ],
        google: [
            {
                title: 'YouTube',
                url: 'https://www.youtube.com/',
                img: 'https://i.ibb.co/VCbzH9N/YouTube.png'
            },
            {
                title: 'Gmail',
                url: 'https://mail.google.com/mail/',
                img: 'https://i.ibb.co/ZB5Lkcy/gmail.png'
            },
            {
                title: 'Google Drive',
                url: 'https://drive.google.com/',
                img: 'https://i.ibb.co/PjRHD4t/google-drive.png'
            },
            {
                title: 'Google Maps',
                url: 'https://maps.google.co.uk/maps',
                img: 'https://i.ibb.co/SRmyhtR/google-maps.png'
            },
        ]
    }
  },  
  mounted (){
  }, 
  methods:{

  },
}
</script>

<style scoped>
    #office {
        display: flex;
        position: fixed;
        top: 112px;
        height: auto;
        left: 0;
        width: 42px;
        flex-direction: column;
        padding: 4px;
        gap: 4px;
        background: var(--widget-background);
        border-radius: 0 8px 8px 0;
        box-shadow: var(--shadow);
    }

    #media {
        display: flex;
        position: fixed;
        top: 58px;
        height: auto;
        right: 0;
        width: 42px;
        flex-direction: column;
        padding: 4px;
        gap: 4px;
        background: var(--widget-background);
        border-radius: 8px 0 0 8px;
        z-index: 10;
        box-shadow: var(--shadow);
    }

    #google {
        display: flex;
        position: fixed;
        top: 112px;
        height: auto;
        right: 0;
        width: 42px;
        flex-direction: column;
        padding: 4px;
        gap: 4px;
        background: var(--widget-background);
        border-radius: 8px 0 0 8px;
        z-index: 10;
        box-shadow: var(--shadow);
    }

.item{
    padding: 6px;
    transition: 0.2s;
}

#profile{
    position: fixed;
    top: 5px;
    right: 8px;
    width: 36px;
    box-shadow: var(--shadow);
    border-radius: 100px;
}

.item:hover{
    transform: scale(1.2);
}

img{
    width: 100%;
}
</style>
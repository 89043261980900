<template>
    <div id="linkedIn">
        <div class="header">

            <i class="fa-brands fa-linkedin-in"></i>

            <div class="input-group">
                <div class="prefix">https://www.linkedin.com/company/</div>
                <input type="text" class="name" v-model="businessName" placeholder="Business Name">
            </div>
            
        </div>

        <div v-if="business == null" class="no-results">
            No Results Found
        </div>
        <div v-else class="body">

            <div class="top-section">

                <img class="profile-pic" :src="business.image_url" :alt="business.company_name">
                
                <div class="profile-info">
                    <div class="profile-name">{{ business.company_name }}</div>
                    <div class="profile-slogan">{{ business.slogan }}</div>
                </div>
            </div>
            
            <div class="additional">
                <p>{{ business.headquarters }}</p>
                <div class="dot"></div>
                <p v-if="business.social_followers != null">{{ business.social_followers.toLocaleString() }} followers</p>
            </div>

            <div class="about">
                <h4>About</h4>
                <p>{{ business.about_us }}</p>
            </div>


            <div v-if="business.specialties.length > 0" class="specialties">
                <h4>Specialties</h4>
                
                <div v-for="spec in business.specialties" class="spec">{{ spec }}</div>
            </div>
        </div>

    </div>

   
  </template>
  
  <script>

    import Swal from 'sweetalert2';
    import $ from "jquery";
    import moment from 'moment';

  export default {
    name: 'LinkedInProfile',
    props:{

    },
    data: function () {
      return {
        businessName: localStorage.getItem("linkedInProfile"),
        business: null,
        loading: false,
        delayTimer: null
      }
    },
    mounted() {
        if (this.businessName)
            this.getProfile();
       
    },
    methods:{
        getProfile: function(){
            
            var self = this;

            $.ajax({
                url: "https://landingpagejenkins.azurewebsites.net/api/general/getLinkedInBusiness?business=" + this.businessName 
            }).done(function (data) {
                
                if (data == null || data == ""){
                    self.business = null;
                    return;
                }
                    
                localStorage.setItem("linkedInProfile", self.businessName);
                data = JSON.parse(data);

                self.loading = false;
                self.business = data;
            }).fail(function() {
                self.loading = false;
            });
        },
        
    },
    watch: {
        businessName: function (value, oldValue) {
            var self = this;
            clearTimeout(this.delayTimer);
            this.delayTimer = setTimeout(function() {
                self.getProfile();
            }, 500);
        }
    },
    computed: {
        
    },
  }
  </script>
  
  <style scoped>

#linkedIn{
    background: white;
    color: #333;
    --linkedIn: #0077b5;
}

.header{
    display: flex;
    align-items: center;
    gap: 14px;
}

.header .fa-linkedin-in{
    color: var(--linkedIn);
    font-size: 20px;
    cursor: none;

}

.input-group{
    display: flex;
    box-shadow: 0px 0px 12px 0px #c9c9c9;
    border-radius: 16px;
    font-size: 12px;
    align-items: center;
    padding: 6px 10px;
    width: 100%;
}

.input-group input{
    border: none;
    outline: none;
    width: 100%;
}
.body{
    height: 100%;
    padding: 12px 0;
}
.no-results{
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.top-section{
    display: flex;
    gap: 8px;
    align-items: flex-end;
}

.profile-pic{
    width: 80px;
    box-shadow: 0px 0px 12px 0px #c9c9c9;
}

.profile-name{
    font-weight: bold;
}

.profile-slogan{
    font-size: 12px;
    opacity: 0.8;
}

h4{
    margin-bottom: 0px;
}

.about p{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.additional{
    display: flex;
    align-items: center;
    margin-top: 12px;
}

.additional p{
    opacity: 0.8;
    margin: 0;
}

.additional .dot{
    margin: 0px 8px;
    height: 6px;
    width: 6px;
    background: var(--linkedIn);
    border-radius: 12px;
}

.spec {
    font-size: 12px;
    padding: 2px 6px;
    border: 2px solid var(--linkedIn);
    display: inline-block;
    margin: 1px 4px;
    border-radius: 16px;
    user-select: none;
}
  </style>
  
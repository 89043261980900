<template>
  <div>
    <div v-for="row in rows" class="loader"></div>
  </div>
</template>

<script>
export default {
  name: 'loading',
  props:{
    rows: {
        type: Number,
        default: 4
    }
  }
}
</script>

<style scoped>

</style>

<template>
    <div class="searchArea">
        <input type="text" class="search" placeholder="Search Widgets" v-model="query" @keyup="searchWidgets">
        <div class="clear" @click="clearSearch">
            <i class="fas fa-times"></i>
            Clear
        </div>
    </div>
    <div class="page">
        <div class="grid">
            <div class="grid-item" v-for="(component, index) in components" :style="getStyle(component)" :index="index">
                {{ component.name }}

                <div class="input-group">
                    <label>Order</label>
                    <input type="number" min="1" v-model="component.order" @change="saveLayout()">
                </div>

                <div class="input-group">
                    <label>Height</label>
                    <input type="number" min="1" v-model="component.height" @change="saveLayout()">
                </div>

                <div class="input-group">
                    <label>Width</label>
                    <input type="number" min="1" max="100" v-model="component.width" @change="saveLayout()">
                </div>
                
                
                <i class="fa-solid fa-left-right" @mousedown="initResize(event, component)"></i>
                <!-- <span class="order">{{ component.order }}</span> -->
                <i class="fa-solid fa-minus" @click="component.active = false"></i>
            </div>
        </div>
        <div class="disabled">
            <div v-for="comp in components">
                
                <div v-if="comp.active == false" class="item">
                    <p>{{ comp.name }}</p>
                    <i class="fa-solid fa-plus" @click="setCompActive(comp)"></i>
                </div>

            </div>
        </div>
    </div>
   
    <!-- <table class="widgets">
        <thead>
            <tr>
                <td class="name">
                    Name
                </td>
                <td class="width">
                    Width
                </td>
                <td class="order">
                    Height
                </td>
                <td class="order">
                    Order
                </td>
                <td class="is-active">
                    Active
                </td>
            </tr>
        </thead>
        <tbody class="widget-table">

            <tr class="widget row" v-for="(component, index) in components" :style="'order: ' + component.order + '; width: ' + component.width + '%;'" :index="index">

                <td class="name">
                    <div>
                        {{ component.name }}
                    </div>
                </td>

                <td class="width">
                    <div>
                        <input type="range" v-model="component.width" @change="saveLayout()">
                        <input type="number" min="1" v-model="component.width" @change="saveLayout()">
                    </div>
                </td>

                <td class="order">
                    <div>
                        <input type="number" min="1" v-model="component.height" @change="saveLayout()">
                    </div>
                </td>

                <td class="order">
                    <div>
                        <input type="number" min="1" v-model="component.order" @change="saveLayout()">
                    </div>
                </td>

                <td class="isActive">
                    <div>
                        <div :class="component.active ? 'active on' : 'active'"
                            @click="component.active = !component.active; saveLayout()"></div>
                    </div>
                </td>

            </tr>
        </tbody>

    </table> -->

    

</template>

<script>

export default {
    name: 'componentsLayout',
    props: {
        componentsOriginal: Array
    },
    data: function () {
        return {
            components: [],
            query: null
        }
    },
    methods: {
        saveLayout: function () {
            var widgets = JSON.stringify(this.components);
            localStorage.setItem("widgets", widgets);
        },
        searchWidgets: function () {
            var query = this.query;

            if (query == null || query == '') {
                $(".widget.row").show();
                return;
            }


            $(".widget.row").each(function (index, element) {
                var name = $(".name", this).text().toLowerCase();

                if (name.includes(query) == false)
                    $(this).hide()
                else
                    $(this).show()
            });
        },
        sortArray: function () {
            var rawObject = [];

            this.components.forEach(comp => {
                rawObject.push(comp);
            });

            rawObject.reverse();

            this.components = rawObject;
        },
        clearSearch: function () {
            this.query = null;
            this.searchWidgets();
        },
        getStyle: function(component){
            if(component.active == false)
                return 'display: none;'

            return `order: ${component.order}; grid-column-end: span ${component.width}; grid-row: span ${component.height} / auto;`
        },
        initResize: function(e, component) {
            window.addEventListener('mousemove', Resize, false);
            window.addEventListener('mouseup', stopResize, false);


            function Resize(e) {
                

                var pixelWidthOfComponent = e.clientX - e.target.parentElement.parentElement.getBoundingClientRect().left// - e.target.offsetLeft;
                var pixelWidthOfContainer = e.target.parentElement.parentElement.offsetWidth;
                
                var newPercentageWidth = Math.floor(pixelWidthOfComponent / pixelWidthOfContainer * 100)
                
                
                component.width = newPercentageWidth;//Math.floor(e.clientX / e.target.offsetLeft * 10)
                
                // element.style.width = (e.clientX - element.offsetLeft) + 'px';
                // element.style.height = (e.clientY - element.offsetTop) + 'px';
            }
            function stopResize(e) {
                window.removeEventListener('mousemove', Resize, false);
                window.removeEventListener('mouseup', stopResize, false);
            }
        },
        setCompActive: function(comp){
            
            var highestOrderSet = 0;
            
            for (var key in this.components) {

                if (this.components[key].active == false)
                    continue;

                if (this.components[key].order > highestOrderSet)
                    highestOrderSet = this.components[key].order;
            }
            
            comp.active = true;
            comp.order = highestOrderSet + 1;
            this.saveLayout();
        }
    },
    mounted() {

        var components = this.componentsOriginal;

        Object.keys(components).forEach(v => 
            components[v].height == null ? 
            components[v].height = 1 : 
            components[v].height = components[v].height
        )

        this.components = components;
    },
    updated: function () {
        this.$nextTick(function () {

            var self = this;

            //dragula([document.querySelector('.widgets .widget-table')]).on('drop', function (el) {
                
            //    var index = $(el).attr("index");
            //    var sibling = $(el).next();
            //    var newIndex = sibling.attr("index")
            //    self.components[index].order = newIndex;
            //});

        })
    },
}
</script>

<style scoped>
.searchArea {
    position: relative;
    margin-bottom: 14px;
}

.widgets {
    width: 100%;
}

table {
    border-spacing: 0;
}

.search {
    width: -webkit-fill-available;
    background-color: #ffffff3b;
    border: none;
    border-radius: 50px;
    font-size: 16px;
    padding: 8px 12px;
    color: var(--font);
}

.clear {
    position: absolute;
    right: 16px;
    top: 8px;
    color: var(--brand-red);
    font-weight: 800;
    cursor: pointer;
}

.row {
    color: var(--font);
    padding: 10px 16px;
    margin-bottom: 12px;
    border-radius: 8px;
    text-transform: capitalize;
    font-size: 12px;
}

/* label {
    padding-bottom: 12px;
} */
.input-group {
    display: flex;
    align-items: center;
    margin: 4px 0;
    font-size: 12px;
    justify-content: center;
}
.name {
    width: 150px;
    font-size: 12px;
}

.name .fa-grip-vertical {
    padding-right: 8px;
}

.active {
    background: grey;
    width: 48px;
    height: 24px;
    border-radius: 100px;
    position: relative;
    cursor: pointer;
    transition: 0.4s;
}

.active:before {
    content: " ";
    background: white;
    position: absolute;
    top: 2px;
    left: 2px;
    height: calc(100% - 4px);
    aspect-ratio: 1 / 1;
    border-radius: 100px;
}

.active.on {
    background: #48cfad;
}

.active.on:before {
    right: 2px;
    left: auto;
}



.order i {
    font-size: 12px;
}

.order label {
    padding-right: 24px;
}

.width div {
    display: flex;
    align-items: center;
}

td {
    padding: 0;
}

td>div {
    padding: 8px 12px;
    background: #ffffff3b;
    margin: 4px 0;
    display: flex;
    align-items: center;
    height: 24px;
}

td.name>div {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    text-transform: capitalize;
}

td.isActive>div {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.grid{
    display: grid;
    grid-template-columns: repeat(100, 1fr);
    align-content: flex-start;
    width: 100%;
}
.grid-item{
    border-radius: 8px;
    background: var(--darken-background);
    margin: 8px;
   min-height: 40px;
    padding: 12px;
    position: relative;
}

.grid-item:hover{
    outline: 2px solid var(--primary);
    
}

.grid-item:hover .fa-left-right{
    display: block;
}

/* .grid-item:hover:after {
    content: "";
    width: 10px;
    height: 10px;
    background: var(--primary);
    border-radius: 10px;
    position: absolute;
    top: -1px;
    left: 50%;
    transform: translateY(-50%);
} */

.grid-item .fa-minus{
    background: var(--brand-red);
    border-radius: 24px;
    color: white;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 6px;
    right: 6px;
}

.fa-left-right{
    display: none;
    color: var(--primary);
    font-size: 22px;
    position: absolute;
    top: 50%;
    right: -12px;
    transform: translateY(-50%) ;
    cursor: grab;
}
/*
.order {
    position: absolute;
    bottom: -14px;
    right: 8px;
    font-size: 60px;
    color: #333;
    opacity: 0.4;
    z-index: 0;
}
*/

.page{
    display: flex;
    height: 100%;
}

.page .disabled{
    width: 275px;
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    background: var(--darken-background);
    padding: 12px;
    border-radius: 8px;
}

.page .disabled .item{
    background: var(--darken-background);
    padding: 6px 12px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
}

.page .disabled .item .fa-plus{
    background: var(--brand-green);
    border-radius: 24px;
    color: #333;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>

<template>

    <Sidebar v-on:edit="canEdit = !canEdit" v-bind:components="enabledComponents" />
    <additionalMedia></additionalMedia>
    <div class="main">
        <Weather v-if="enabledComponents.weather.active" :style="getStyle(enabledComponents.weather)" />
        <timeOverview v-if="enabledComponents.timeOverview.active" :style="getStyle(enabledComponents.timeOverview)" />
        <news v-if="enabledComponents.news.active" :style="getStyle(enabledComponents.news)" />
        <bookmarks v-if="enabledComponents.bookmarks.active" :style="getStyle(enabledComponents.bookmarks)" />
        <todos v-if="enabledComponents.todoList.active" :style="getStyle(enabledComponents.todoList)" />
        <webTesting v-if="enabledComponents.webTesting.active" :style="getStyle(enabledComponents.webTesting)" />
        <crypto v-if="enabledComponents.cryptoCurrency.active" :style="getStyle(enabledComponents.cryptoCurrency)" />
        <!-- <football v-if="enabledComponents.footballScores.active" :style="getStyle(enabledComponents.footballScores)" /> -->
        <spotify v-if="enabledComponents.spotify.active" :style="getStyle(enabledComponents.spotify)" />
        <google v-if="enabledComponents.google.active" :style="getStyle(enabledComponents.google)" />
        <!-- <calendar v-if="enabledComponents.calendar.active" :style="getStyle(enabledComponents.calendar)" /> -->
        <!-- <notes v-if="enabledComponents[5].active" :style="getStyle(5)" /> -->
        <!-- <div v-for="(component, index) in enabledComponents" :is="component.name"  :style="getStyle(index)" ></div> -->
        <lighthouse v-if="enabledComponents.lighthouse.active" :style="getStyle(enabledComponents.lighthouse)" />
        <siteMonitor v-if="enabledComponents.siteMonitor.active" :style="getStyle(enabledComponents.siteMonitor)" />
        <!-- <linkedInProfile v-if="enabledComponents.linkedInProfile.active" :style="getStyle(enabledComponents.linkedInProfile)" />  -->
    </div>

    <img v-if="user.useBackgroundImage" :src="user.backgroundImage" alt="background image" id="background-img" />

    <!-- <div id="loading">
        <div class="bouncing-loader">
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div> -->
    
</template>

<script>
import $ from "jquery";
import {getUser, setTheme, getCSS} from "/src/helper.js";

import Sidebar from './components/core/sidebar.vue'
import additionalMedia from './components/widgets/additionalMedia/additionalMedia.vue'
import Weather from './components/widgets/weather/weather.vue'
import Loader from './components/utilities/loading.vue'
import timeOverview from './components/widgets/time-overview/time-overview.vue'
import news from './components/widgets/news/news.vue'
import bookmarks from './components/widgets/bookmarks/bookmarks.vue'
import todos from './components/widgets/todo/todo.vue'
import notes from './components/widgets/notes/notes.vue'
import webTesting from './components/widgets/developer-tools/web-testing.vue'
import crypto from './components/widgets/cryptocurrency/cryptocurrency.vue'
import football from './components/widgets/football-scores/football.vue'
import spotify from './components/widgets/spotify/spotify.vue'
import google from './components/widgets/google/search.vue'
import calendar from "./components/widgets/calendar/calendar.vue"
import Lighthouse from "./components/widgets/Lighthouse/Lighthouse.vue";
import siteMonitor from "./components/widgets/site-monitor/site-monitor.vue";
import linkedInProfile from "./components/widgets/linkedInProfile/linkedInProfile.vue";

var components = {
    Sidebar,
    additionalMedia,
    Weather,
    Loader,
    timeOverview,
    news,
    bookmarks,
    todos,
    notes,
    webTesting,
    crypto,
    football,
    spotify,
    google,
    calendar,
    Lighthouse,
    siteMonitor,
    linkedInProfile
}

export default {
    name: 'App',
    components: components,
    data: function () {
        return {
            canEdit: false,
            html: 'Edit Mode',
            user: getUser(),
            enabledComponents: {
                "google": {
                    "name": "Google",
                    "active": true,
                    "width": "100",
                    "order": 1
                },
                "timeOverview":{
                    "name": "Time Overview",
                    "active": true,
                    "width": 20,
                    "order": 2
                },
                "weather":{
                    "name": "Weather",
                    "active": true,
                    "width": 30,
                    "order": 5
                },
                "bookmarks":{
                    "name": "Bookmarks",
                    "active": true,
                    "width": 20,
                    "order": 7
                },
                "cryptoCurrency":{
                    "name": "Crypto Currency",
                    "active": true,
                    "width": 30,
                    "order": 3
                },
                "news":{
                    "name": "News",
                    "active": true,
                    "width": 20,
                    "order": 4,
                    "height": 2
                },
                "todoList":{
                    "name": "Todo List",
                    "active": true,
                    "width": 30,
                    "order": 6
                },
                // "calendar":{
                //     "name": "Calendar",
                //     "active": true,
                //     "width": 40,
                //     "order": 8
                // },
                "lighthouse":{
                    "name": "Lighthouse",
                    "active": true,
                    "width": 40,
                    "order": 8,
                },
                "siteMonitor":{
                    "name": "Site Monitor",
                    "active": true,
                    "width": 40,
                    "order": 6
                },
                // "linkedInProfile":{
                //     "name": "LinkedIn Profile",
                //     "active": true,
                //     "width": 30,
                //     "order": 5
                // },
                
                "webTesting":{
                    "name": "SSL Check",
                    "active": false,
                    "width": 25,
                    "order": 10
                },
                // "footballScores":{
                //     "name": "Football Scores",
                //     "active": false,
                //     "width": "100",
                //     "order": 9
                // },
                "spotify": {
                    "name": "Spotify",
                    
                    "active": true,
                    "width": 50,
                    "order": 3,
                    "height": 3
                },
                
            },
            background: null,
            scripts: [
                // 'https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js',
                // '//cdn.jsdelivr.net/npm/sweetalert2@11',
                // 'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.js',
                // 'https://cdnjs.cloudflare.com/ajax/libs/codemirror/5.16.0/codemirror.min.js',
                // 'https://cdnjs.cloudflare.com/ajax/libs/codemirror/5.16.0/mode/javascript/javascript.min.js',
                'https://cookieconsent.popupsmart.com/src/js/popper.js'
            ]
        }
    },
    mounted() {
        this.addScripts();
        setTheme();
        this.getWidgets();
    },
    methods: {
        addScripts: function(){

            $.each(this.scripts, function (indexInArray, valueOfElement) { 
                $("<script/>", {
                    src: valueOfElement
                }).appendTo("body")
            });

            $("#customCSS").text(getCSS());

            setTimeout(() => {
                window.start.init({ Palette: "palette6", Mode: "floating left", Theme: "classic", Time: "5", })
            }, 1000);
        },
        getStyle: function (item) {
            //return `width: calc(${item.width}% - 52px); order: ${item.order}`
            return `grid-column-end:  span ${item.width}; order: ${item.order}; grid-row: span ${item.height || 1};`
        },
        getWidgets: function () {

            var widgets = localStorage.getItem("widgets");
            if (widgets != null) {
                this.enabledComponents = JSON.parse(widgets);
            }
        },
    },

}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.2/css/all.min.css');
/* @import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.min.css'); */

:root {
    --primary: #ff5252;
    --background: #e3e3e3;
    --font: #333;
    --shadow: 5px 0px 10px 0px #2424240f;
    --darken-background: #0000002e;
    --border-radius: 16px;
    --brand-red: #ed5565;
    --brand-green: #55ed8f;
    --background-opacity: 80%;
}

body {
    margin: 0;
    background-color: var(--background);
    color: var(--font);
    font-family: 'Poppins', sans-serif;
    font-weight: var(--font-weight);
    user-select: none;
}

input {
    font-family: 'Poppins', sans-serif;
}

    .main {
        position: fixed;
        height: -webkit-fill-available;
        width: calc(100% - 150px);
        right: 0;
        padding: 20px;
        padding-right: 75px;
        height: 100%;
        /* display: flex;
        flex-wrap: wrap; */

        display: grid;
        grid-template-columns: repeat(100, 1fr);


        align-content: flex-start;
        /* gap: 24px; */
        overflow: auto;
    }

@media (max-width: 1600px){
    .main {
        padding: 32px;
        padding-right: 60px;
        width: calc(100% - 120px);
    }
}

@media (max-width: 900px){

    .main{
        flex-wrap: nowrap;
        flex-direction: column;
    }

    .main > * {
        width: 100% !important;
        grid-column-end: span 100 !important;
    grid-row: span 1 / auto !important;
    }
}

.main>* {
    background: var(--widget-background);
    box-shadow: var(--shadow);
    border-radius: var(--border-radius);
    padding: 20px;
    /* max-width: calc(100% - 40px); */
    margin: 8px;
    
}

h1 {
    margin: 0;
}

hr {
    background: var(--darken-background);
    height: 2px;
    border: none;
    margin: 18px 0;
}

i {
    cursor: pointer;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
    scrollbar-width: auto;
    scrollbar-color: var(--font) var(--background);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 12px;
}

*::-webkit-scrollbar-track {
    background: transparent;
}

*::-webkit-scrollbar-thumb {
    background-color: var(--font);
    border-radius: 10px;
}

#background-img {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: var(--background-opacity);
}


/* Hide scrollbar for Chrome, Safari and Opera */
.main::-webkit-scrollbar {
    display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
/* * {
    -ms-overflow-style: none;
    scrollbar-width: none;
} */

input[type="number"]{
    background: none;
    border: none;
    color: var(--font);
    text-align: right;
    width:50px;
}

input[type="range"]{
    background: var(--font);
    border: none;
    outline: none;
    appearance: none;
    height: 8px;
    border-radius: 20px;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%; 
    background: var(--primary);
    cursor: pointer;
}

.text-input{
    width: -webkit-fill-available;
    background-color: #ffffff3b;
    border: none;
    border-radius: 50px;
    font-size: 16px;
    padding: 4px 12px;
    color: var(--font);
}

.btn-primary {
    padding: 8px 28px;
    background: var(--primary);
    border-radius: 6px;
    cursor: pointer;
    margin: 6px 0;
    text-align: center;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;
    transition: 0.2s;
}

.btn-primary:hover{
    filter: brightness(0.8);
}

h1, h2, h3, h4, h5, h6{
    font-weight: calc(var(--font-weight) + 100) !important;
}

p{
    font-size: 12px;
}

@keyframes bouncing-loader {
    to {
      opacity: 0.1;
      transform: translate3d(0, -16px, 0);
    }
}

@keyframes disappear {
    to {
      opacity: 0.0;
      display: none;
      z-index: -10;
    }
}

@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
}

#loading{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: var(--background);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    
    animation-iteration-count: 1;
    animation: disappear forwards ease-in-out 0.5s;
    animation-delay: .5s;
}

#loading .bouncing-loader {
    display: flex;
    justify-content: center;
    animation: disappear forwards ease-in-out  .5s;
}
      
#loading .bouncing-loader > div {
    width: 16px;
    height: 16px;
    margin: 3rem 0.2rem;
    background: var(--font);
    border-radius: 50%;
    animation: bouncing-loader 0.6s infinite alternate;
    animation-delay: 0.1s;
}

#loading .bouncing-loader > div:nth-child(2) {
    animation-delay: 0.2s;
}

#loading .bouncing-loader > div:nth-child(3) {
    animation-delay: 0.3s;
}

.swal2-modal{
    background: var(--background) !important;
    color: var(--font) !important;
}

.swal2-html-container{
    display: flex !important;
    flex-direction: column;
}

.swal2-html-container span{
    display: flex;
    align-items: center;
    justify-content: center;
}
.swal2-html-container span input[type="checkbox"]{
    margin: 16px;
}

.swal2-styled.swal2-confirm{
    background: var(--primary) !important;
    color: var(--background) !important;
}

.swal2-input {
    border-color: var(--darken-background) !important;
}

.slick-prev {
    left: 0 !important;
    z-index: 1;
}

.slick-next {
    right: 0 !important;
    z-index: 1;
}

.loader {
    width: 100%;
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #F6F6F6;
    background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
    background-size: 800px 104px;
    position: relative;
    border-radius: 8px;
    opacity: 0.1;
}

@keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}
</style>

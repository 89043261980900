<template>
<div id="time-overview">
    <input type="text" v-model="user.name" @change="updateName()">
    <p>It is currently 
      <span>{{minutes}}</span> minutes past 
      <span>{{hour}}</span> in the <span>{{timeOfDay}}</span>
    </p>

    <img src="https://www.firewatchgame.com/images/parallax/parallax7.png" alt="mountain man" class="background"/>
</div>
</template>

<script>
import {getUser, setUser} from "/src/helper.js";

export default {
  name: 'time-overview',
  data: function () {
    return {
      user: getUser(),
      minutes: null,
      hour: null,
      timeOfDay: null,
    }
  },
  methods: {
    getTime: function(){

        var d = new Date();
        this.hour = d.getHours();
        this.minutes = d.getMinutes();

        if (this.hour >= 0) 
            this.timeOfDay = "morning";
    
        if (this.hour > 12) 
            this.timeOfDay = "afternoon";
    
        if (this.hour > 17)
            this.timeOfDay = "evening";
    
        if (this.hour > 12)
            this.hour = this.hour - 12;
    },
    updateName: function (){
        setUser(this.user);
    }
  },
  mounted (){
    this.getTime();

    setInterval(() => {
        this.getTime();
    }, 5000);
  }
}
</script>

<style scoped>

#time-overview{
  position: relative;
  overflow: hidden;
  display: flex;
    flex-direction: column;
}
  span{
    color: var(--primary);
  }

  p{
    font-size: 24px;
    user-select: none;
    z-index: 1;
    position: relative;
  }

  input{
    background: none;
    border: none;
    color: var(--font);
    font-size: 36px;
    z-index: 1;
    position: relative;
  }

  input:active, input:focus{
    border: none;
  }

  .background{
    background-image: url("http://www.firewatchgame.com/images/parallax/parallax7.png");
    position: absolute;
    left: -150px;
    bottom: -100px;
    width: 1000px;
    filter: grayscale(1) brightness(2) opacity(0.6);
    z-index: 0;
  }
</style>

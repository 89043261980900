<template>
    <div id="lighthouse">
        <div class="header">
            <p> {{ reports.length }} / 5 Sites</p>
        </div>

        <div v-for="(report, index) in reports" class="report">

            <a :href="report.finalUrl" class="site" target="_blank">
                <img :src="'https://icon.horse/icon/' + report.finalUrl.replaceAll('https://', '')" alt="">

                <p>
                    {{ report.finalUrl }}
                </p>
            </a>

            <div class="scores">
                <div class="score">

                    <div class="meter">
                        <div class="fill" :style="'height:' + getMeter(report.categories.accessibility.score) + '; background:'+ getColour(report.categories.accessibility.score)"></div>
                    </div>

                    <div class="info">
                        <div class="figure">
                            {{  getMeter(report.categories.accessibility.score) }}
                        </div>

                        <div class="title">
                            Accessibility
                        </div>
                    </div>
                    
                </div>

                <div class="score">

                    <div class="meter">
                        <div class="fill" :style="'height:' + getMeter(report.categories['best-practices'].score) + '; background:'+ getColour(report.categories['best-practices'].score)"></div>
                    </div>

                    <div class="info">
                        <div class="figure">
                            {{  getMeter(report.categories['best-practices'].score) }}
                        </div>

                        <div class="title">
                            Best Practices
                        </div>
                    </div>

                </div>

                <div class="score">

                    <div class="meter">
                        <div class="fill" :style="'height:' + getMeter(report.categories['performance'].score) + '; background:'+ getColour(report.categories['performance'].score)"></div>
                    </div>

                    <div class="info">
                        <div class="figure">
                            {{  getMeter(report.categories['performance'].score) }}
                        </div>

                        <div class="title">
                            Perfomance
                        </div>
                    </div>

                </div>

                <div class="score">

                    <div class="meter">
                        <div class="fill" :style="'height:' + getMeter(report.categories['seo'].score) + '; background:'+ getColour(report.categories['seo'].score)"></div>
                    </div>

                    <div class="info">
                        <div class="figure">
                            {{  getMeter(report.categories['seo'].score) }}
                        </div>

                        <div class="title">
                            SEO
                        </div>
                    </div>

                </div>
            </div>

            <div class="footer">

                <div class="refresh"  @click="getReport(report.finalUrl)">
                    <i class="fa-solid fa-rotate"></i>

                    Last Run: {{ getDate(report.fetchTime)  }}
                </div>
                
                <i  @click="deleteReport(index)" class="far fa-trash-alt" title="Delete Report"></i>
                
            </div>

            <div v-if="loadingUrl == report.finalUrl"  class="loader">
                <div class="box box1"></div>
                <div class="box box2"></div>
                <div class="box box3"></div>
                <div class="box box4"></div>
                <div class="box box5"></div>

                <div class="progress">
                    <div class="bar"></div>
                </div>
            </div>
        </div>

        <div v-if="isLoadingNewSite" class="report">
            <div class="loader">
                <div class="box box1"></div>
                <div class="box box2"></div>
                <div class="box box3"></div>
                <div class="box box4"></div>
                <div class="box box5"></div>

                <div class="progress">
                    <div class="bar"></div>
                </div>
            </div>
        </div>

        <a v-if="reports.length < 5" @click="openPopup" class="btn-primary">
            <i class="fas fa-plus"></i>
            Add Site
        </a>
    </div>
  </template>
  
  <script>

    import Swal from 'sweetalert2';
    import $ from "jquery";
    import moment from 'moment';

  export default {
    name: 'Lighthouse',
    props:{

    },
    data: function () {
      return {
        reports: this.getReports(),
        loadingUrl: null,
      }
    },
    methods:{
        getReports: function(){
            var storedReports = localStorage.getItem("LighthouseReports");

            if (storedReports == null || storedReports == "")
                return [];
                
            return JSON.parse(storedReports);
        },
        openPopup: function(){
            Swal.fire({
                title: "Add a Report",
                text: "Please enter the URL:",
                input: 'text',
                showCancelButton: true        
            }).then((result) => {
                if (result.value) {
                    this.getReport(result.value);
                }
            });
        },
        getReport: function(site){

            this.loadingUrl = site;

            var self = this;

            $.ajax({
                url: "https://landingpagejenkins.azurewebsites.net/api/general/getLighthouseReport?domain=" + site 
            }).done(function (data) {
                var JsonFile = JSON.parse(data);

                $.ajax({
                    url: JsonFile.data
                }).done(function (response) {
                    
                    var alreadyExistingSiteIndex = self.reports.findIndex(x => x.finalUrl == site);

                    if (alreadyExistingSiteIndex < 0){
                        self.reports.push(response);
                    } else {
                        self.reports[alreadyExistingSiteIndex] = response;
                    }

                    
                    localStorage.setItem("LighthouseReports", JSON.stringify(self.reports));
                    self.loadingUrl = null;
                });
            }).fail(function() {
                self.loadingUrl = null;
            });
        },
        getMeter: function(score){
            return (score * 100) + "%";
        },
        getColour: function(score){
            
            if(score < 0.50)
                return '#ed5565';

            if(score < 0.90)
                return '#FFAA33';

            return '#55ed8f';
        },
        getDate: function(date){
            return moment(date).format('LLLL');
        },
        deleteReport: function(index){
            this.reports.splice(index, 1);
            localStorage.setItem("LighthouseReports", JSON.stringify(this.reports));
        }
        
    },
    computed: {
        isLoadingNewSite: function () {
            if (this.loadingUrl == null)
                return false;
            
            return this.reports.findIndex(x => x.finalUrl == this.loadingUrl) < 0;
        },
        isAdvanced: function (){

            if (this.$el == null)
                return false;
            debugger
            return this.$el.innerWidth > 800;
        }
    },
  }
  </script>
  
  <style scoped>
    .header{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .scores{
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        gap: 10px;
        min-width: 500px;
        justify-content: flex-end;
        user-select: none;
    }

    .score{
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        gap: 8px;
    }

    .meter{
        width: 8px;
        background: var(--darken-background);
        height: 80px;
        border-radius: 6px;
        display: flex;
        align-items: flex-end;
    }

    .meter .fill{
        height: 80%;
        background: green;
        width: 100%;
        border-radius: 12px;
        animation: fillTransition 0.6s;
        animation-delay: 0.5s;
    }

    @keyframes fillTransition {
        0% {
            height: 0px;
        }
        100% {
            
        }
    }

    .info{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
        padding: 0 4px;
    }

    .figure{
        text-align: center;
        font-size: 16px;
        height: 100%;
        padding: 10px;
        aspect-ratio: 1 / 1;
        border: 4px solid var(--font);
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .title{
        font-size: 12px;
    }

    .report{
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        justify-content: space-between;
        margin-bottom: 10px;
        background: var(--darken-background);
        padding: 12px 8px;
        border-radius: 8px;
        position: relative;
        min-height: 110px;
    }

    .site{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        color: var(--font);
        text-decoration: none;
    }

    img{
        width: 48px;
        border-radius: 48px;
    }

    .footer {
        width: 100%;
        font-size: 12px;
        display: flex;
        align-items: center;
        cursor: pointer;
        
        padding: 0 4px;
        justify-content: space-between;
    }

    .fa-trash-alt{
        font-size: 16px;
        color: var(--brand-red);
    }

    .refresh{
        display: flex;
        gap: 12px;
        align-items: center;
        opacity: 0.6;
    }

    .progress{
        width: 80%;
        position: absolute;
        bottom: 18px;
        background: var(--font);
        height: 8px;
        border-radius: 4px;
        display: flex;
    }

    .progress .bar{
        width: 100%;
        background: var(--primary);
        height: 100%;
        border-radius: 4px;
        animation: progressBar 30s;
    }


    @keyframes progressBar {
        0%{
            width: 0%;
        }
        100%{
            width: 100%;
        }
    }
.loader{
    display: flex;
    gap: 5px;
    justify-content: center;
    --boxSize: 6px;
    --gutter: 4px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    backdrop-filter: blur(20px);
    border-radius: 8px;
}

.loader .box{
    transform: scaleY(.4);
    transform-origin: bottom;
    height: 60px;
    width: var(--boxSize);
    background: var(--font);
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    border-radius: 8px;
}

.loader .box1{
  animation-name: quiet;
}

.loader .box2{
  animation-name: normal;
}

.loader .box3{
  animation-name: quiet;
}

.loader .box4{
  animation-name: loud;
}

.loader .box5{
  animation-name: quiet;
}


@keyframes quiet {
  25%{
    transform: scaleY(.6);
  }
  50%{
    transform: scaleY(.4);
  }
  75%{
    transform: scaleY(.8);
  }
}

@keyframes normal {
  25%{
    transform: scaleY(1);
  }
  50%{
    transform: scaleY(.4);
  }
  75%{
    transform: scaleY(.6);
  }
}
@keyframes loud {
  25%{
    transform: scaleY(1);
  }
  50%{
    transform: scaleY(.4);
  }
  75%{
    transform: scaleY(1.2);
  }
}

  </style>
  
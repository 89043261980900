<template>
    <div id="crypto-currency">

        <div class="rotator" v-if="results != null">
            <div v-for="crypto in results">

                <div class="crypto">
                    <img :src="crypto.iconUrl" height="32" width="32" :alt="crypto.name">

                    <div class="info">
                        <p>{{ crypto.name }}</p>

                        <div class="change">
                        <i
                            :class="crypto.change >= 0 ? 'fa-solid fa-arrow-trend-up' : 'fa-solid fa-arrow-trend-down'"></i>
                        <p :class="crypto.change >= 0 ? 'green' : ''">{{ crypto.change }}%</p>
                    </div>
                    </div>
                    
                </div>

            </div>
        </div>

        <div class="rotator" v-if="results != null">
            <div v-for="crypto in results2">

                <div class="crypto">
                    <img :src="crypto.iconUrl" height="32" width="32" :alt="crypto.name">

                    <div class="info">
                        <p>{{ crypto.name }}</p>

                        <div class="change">
                        <i
                            :class="crypto.change >= 0 ? 'fa-solid fa-arrow-trend-up' : 'fa-solid fa-arrow-trend-down'"></i>
                        <p :class="crypto.change >= 0 ? 'green' : ''">{{ crypto.change }}%</p>
                    </div>
                    </div>
                    
                </div>

            </div>
        </div>

        <div class="dashboard" v-if="results != null">

            <div class="item">
                <p class="title">Biggest Increase</p> 
                <div class="crypto">
                    
                    <img :src="biggestIncrease.iconUrl" height="32" width="32" :alt="biggestIncrease.name">

                    <div class="info">
                        <p>{{ biggestIncrease.name }}</p>

                        <div class="change">
                            <i
                                :class="biggestIncrease.change >= 0 ? 'fa-solid fa-arrow-trend-up' : 'fa-solid fa-arrow-trend-down'"></i>
                            <p :class="biggestIncrease.change >= 0 ? 'green' : ''">{{ biggestIncrease.change }}%</p>
                        </div>
                    </div>
                    
                </div>
            </div>
            
            <div class="item">
                <p class="title">Biggest Decrease</p> 
                <div class="crypto">
                    
                    <img :src="biggestDecrease.iconUrl" height="32" width="32" :alt="biggestDecrease.name">

                    <div class="info">
                        <p>{{ biggestDecrease.name }}</p>

                        <div class="change">
                            <i
                                :class="biggestDecrease.change >= 0 ? 'fa-solid fa-arrow-trend-up' : 'fa-solid fa-arrow-trend-down'"></i>
                            <p :class="biggestDecrease.change >= 0 ? 'green' : ''">{{ biggestDecrease.change }}%</p>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>

        <div v-else class="loader" v-for="num in 3">

        </div>

    </div>
</template>
   
<script>
import $ from "jquery";
import 'slick-carousel'
import loading from '/src/components/utilities/loading.vue'

export default {
    name: 'crypto-currency',
    data: function () {
        return {
            results: null,
            results2: null
        }
    },
    mounted() {
        this.getData();

        var self = this;

        $( window ).resize(function() {
            $('.rotator').slick('unslick');
            self.reformatRotator();
        });

    },
    methods: {
        getData: function () {
            var self = this;

            $.ajax({
                url: "https://landingpagejenkins.azurewebsites.net/api/general/getCoins" 
            }).done(function (response) {
                response = JSON.parse(response);
                
                self.allCoins = response.data.coins;
                self.results = response.data.coins.splice(0, 10);
                self.results2 = response.data.coins.splice(11, 10);
            });
        },
        reformatRotator: function(){
            var width = $(this.$el).width();
            var toShow = 1;

            if (width > 1920){
                toShow = 10
            } else if (width > 1600){
                toShow = 8
            } else if (width > 1200){
                toShow = 6
            } else if (width > 900){
                toShow = 4
            } else if (width > 600){
                toShow = 3
            } else if (width > 400){
                toShow = 2
            }

            $('.rotator').slick({
                slidesToShow: toShow,
                slidesToScroll: toShow,
                autoplay: true,
                autoplaySpeed: 10000,
            });
        }
    },
    computed: {
        biggestIncrease: function () {

            // return this.allCoins.reduce(
            //     (prev, current) => {
            //         return prev.change > current.change ? prev : current
            //     }
            // );
            
            var highestCoin = this.allCoins[0];
            this.allCoins.forEach(coin => {
                if(parseInt(coin.change) > parseInt(highestCoin.change))
                    highestCoin = coin;
            });

            return highestCoin;
        },
        biggestDecrease: function () {

            var lowestCoin = this.allCoins[0];
            this.allCoins.forEach(coin => {
                if(parseInt(coin.change) < parseInt(lowestCoin.change))
                lowestCoin = coin;
            });

            return lowestCoin;
        }
    },
    updated: function () {
        this.$nextTick(function () {
            this.reformatRotator(); 
        })
    },
    components: {
        loading,
    }
}
</script>
   
<style scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.min.css'); 
#crypto-currency {
    display: flex;
    flex-direction: column;
    gap: 12px;
} 
.loader{
    height: 60px;
}
.crypto {
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: flex-start;
    padding-left: 50px;
    height: 100%;
}

img {
    width: 32px;
    ;
}

p{
    margin: 0;
}

.fa-arrow-trend-down, .change p {
    color: var(--brand-red);
}

.fa-arrow-trend-up, .change p.green {
    color: var(--brand-green);
}

.change {
    cursor: default;
    font-size: 22px;
    display: flex;
    align-items: center;
    gap: 14px;
}


.dashboard{
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 12px;
    display: grid;
}

.dashboard .item{
    background: var(--darken-background);
    padding: 10px 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.dashboard .item .title{
    font-size: 16px;
}

.dashboard .item .crypto{
    padding: 0;
}
</style>
<template>
    <div class="grid-item extend">
        <a :href="item.url" target="_blank">
            <div class="card">
                <img :src="validateImg(item.image)" :alt="item.title" loading="lazy">
                <div class="bottom">
                    <p class="title">
                        {{item.title}}
                    </p>
                    <div class="source">
                        {{item.source.name}}
                    </div>
                </div>
            </div>
        </a>
    </div>
</template>

<script>


export default {
  name: 'item',
  props:{
    item: Object,
    index: Number
  },
  methods:{
    validateImg: function(img){
        if (img == null || img == undefined || img == ""){
            return 'https://picsum.photos/500?random=' + (this.index + 1);
        } else {
            return img;
        }
    }
  },
}
</script>

<style scoped>
.grid-item{
    box-shadow: var(--shadow);
    border-radius: var(--border-radius);
    color: white;
    overflow: hidden;
}
.card{
    position: relative;
    min-height: 250px;
    height: 100%;
}
img{
    width: 100%;
    border-radius: var(--border-radius);
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s;
}

.card:hover img{
    transform: scale(1.05);
    /* filter: blur(2px); */
}

.card:hover .bottom{
    height: 90%;
    padding-bottom: 24px;
}

.bottom{
    padding: 12px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(100% - 24px);
    background: linear-gradient(180deg, transparent, #000000d9 60%);
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    text-align: right;
    color: white;
    border-radius: 0px 0px var(--border-radius) var(--border-radius);
    transition: 0.2s;
}

.title{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

a, a:visited, a:focus, a:active{
    color: var(--font);
    text-decoration:none;
}
</style>
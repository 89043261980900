<template>
    <div id="google-search">
        <div class="search-bar" tabindex="1" @blur="results = []">

            <input v-model="query" @keyup="performSearch" @keyup.enter="goToGoogle" type="text" placeholder="Google Search">
            
            <i v-if="query != null" @click="query = null" class="fa-solid fa-xmark"></i>
            
            <i class="fa-brands fa-google"></i>

            <div :class="results.length > 0 && query != null ? 'suggestions active' : 'suggestions'" >
                <a v-for="result in results" class="suggestion" :href="'https://www.google.com/search?q=' + result">
                    <i class="fa-solid fa-magnifying-glass"></i>
                    {{result}}
                </a>
            </div>

        </div>
    </div>
</template>

<script>
import $ from "jquery";
import {getUser, setTheme, getCSS, setUser} from "/src/helper.js";

export default {
    name: 'search',
    data: function () {
        return {
            results: [],
            query: null,
            delayTimer: null
        }
    },
    mounted() {
        
    },
    methods: {
        performSearch: function(){
            var self = this;

            clearTimeout(this.delayTimer);
            this.delayTimer = setTimeout(function() {
                const settings = {
                    "async": true,
                    "crossDomain": true,
                    "url": "https://keyword-autosuggest.p.rapidapi.com/autosuggest?q=" + self.query,
                    "method": "GET",
                    "headers": {
                        "X-RapidAPI-Key": "b11910c071msh3ea041eaa90af26p14f827jsn36ddcc0c9d92",
                        "X-RapidAPI-Host": "keyword-autosuggest.p.rapidapi.com"
                    }
                };

                $.ajax(settings).done(function (response) {
                    self.results = response.result;
                });
            }, 500);
        },
        goToGoogle: function(){
            window.location.href = 'https://www.google.com/search?q=' + this.query;
        }
    },
    
}
</script>

<style scoped>
#google-search{
    padding: 0;
    background: none;
    box-shadow: none;
}

.fa-google,.fa-xmark{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
}

.fa-xmark{
    position: absolute;
    right: 46px;
    top: 0;
    height: 35px;
    color: var(--brand-red);
    cursor: pointer;
}

.search-bar{
    background: #fff;
    padding: 4px 14px;
    border-radius: 24px;
    color: #333;
    display: flex;
    position: relative;
}

input{
    outline:  none;
    border: none;
    width: 100%;
    font-size: 16px;
}

.suggestions{
    position: absolute;
    top: 100%;
    background: white;
    padding: 0 8px;
    width: calc(100% - 46px);
    left: 14px;
    z-index: 1;
    border-radius: 0 0 12px 12px;
    height: 0;
    overflow: hidden;
    transition: 0.4s;
    display: flex;
    flex-direction: column;
}

.suggestions.active{
    height: auto;
    opacity: 1;
    padding: 8px;
}

.suggestion{
    padding: 0px 8px;
    cursor: pointer;
    transition: 0.1s;
    color: #333;
    text-decoration: none;
}

.suggestion:hover{
    background: #f1f1f1;
}

.fa-magnifying-glass{
    padding-right: 6px;
    font-size: 14px;
    opacity: 0.4;
}
</style>
